import React from 'react';
import withLayout from '@/base/containers/withLayout';
import Hero from '@/components/sections/Hero';
import loadable from '@loadable/component';
import type { PlatformTemplateProps, PlatformTypes } from './Platform.types';
import * as styles from './Platform.module.scss';

const TestimonialSection = loadable(() => import('../../sections/TestimonialSection'));
const GradientList = loadable(() => import('../../sections/GradientList'));
const QuoteModule = loadable(() => import('../../modules/QuoteModule'));

export function Platform({ content }: StoryblokPage<PlatformTypes>) {
  const {
    eyebrow,
    body,
    hero_image,
    testimonial_body,
    grid,
    title,
    steps,
    quote_title,
    quote,
    author_image,
    author_name,
    author_role,
    link,
    link_label,
    quote_image,
    quote_grid,
    cta_body,
  } = content;

  const showQuoteModule = quote_title && quote;

  return (
    <section className="container">
      <Hero eyebrow={eyebrow} body={body} image={hero_image} image_position="right" className={styles} widestContent />
      <TestimonialSection body={testimonial_body} grid={grid} />
      <GradientList title={title} steps={steps} cta={cta_body}>
        {showQuoteModule && (
          <QuoteModule
            title={quote_title}
            quote={quote}
            image={quote_image}
            grid={quote_grid}
            authorImage={author_image}
            authorName={author_name}
            authorRole={author_role}
            link={link}
            linkLabel={link_label}
          />
        )}
      </GradientList>
    </section>
  );
}

export default function PlatformTemplate({ pageContext, location }: PlatformTemplateProps) {
  return withLayout(Platform, { ...pageContext, location });
}
