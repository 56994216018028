export function groupLinksByLetter(array: StoryblokEntry<{ list_page_title?: string }>[]) {
  const alphabeticallyGrouped = array.reduce(
    (r: { [key: string]: { group: string; children: StoryblokEntry[] } }, e) => {
      const group = e.content.list_page_title?.[0] || e.name[0];

      if (!r[group]) {
        r[group] = { group, children: [e] };
      } else {
        r[group].children.push(e);
      }
      return r;
    },
    {},
  );

  return alphabeticallyGrouped;
}
