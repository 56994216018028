import React from 'react';
import Link from '@/components/elements/Link';
import type { ResourcesListTypes } from './ResourceList.types';
import * as styles from './ResourceList.module.scss';

export function ResourceList({ resourcesList }: ResourcesListTypes) {
  if (!resourcesList.length) return null;
  return (
    <ul className={styles.wrapper}>
      {resourcesList.map(letterGroup => (
        <li
          key={`letter-${letterGroup.group}`}
          id={`section-${letterGroup.group}`}
          className={styles.letterGroupContainer}
        >
          <h3 className={`heading6 ${styles.letterHeading}`}>{letterGroup.group}</h3>
          <div className={styles.linksHolder}>
            {letterGroup.children.length &&
              letterGroup.children.map(({ full_slug, name, uuid, content: { list_page_title } }) => (
                <Link
                  href={full_slug}
                  text={list_page_title || name}
                  key={uuid}
                  className={styles.anchorLink}
                  isInline
                />
              ))}
          </div>
        </li>
      ))}
    </ul>
  );
}
