import React from 'react';
import withLayout from '@/base/containers/withLayout';
import Hero from '@/components/sections/Hero';
import * as HeroStyles from '@/components/sections/Hero/Hero.module.scss';
import type { NotFoundTypes } from './NotFound.types';
import '@/base/styles/main.scss';
import * as styles from './NotFound.module.scss';

export function NotFound({ content }: StoryblokPage<NotFoundTypes>) {
  return (
    <main className="container">
      <Hero {...content} image_position="center" spacing="md" className={{ ...HeroStyles, ...styles }} />
    </main>
  );
}

export default function NotFoundTemplate({ pageContext, location }: StoryblokTemplate<NotFoundTypes>) {
  return withLayout(NotFound, { ...pageContext, location });
}
