import React from 'react';
import SbEditable from 'storyblok-react';
import Header from '@/components/sections/Header';
import Footer from '@/components/sections/Footer';
import NotificationBar from '@/components/modules/NotificationBar';
import { LayoutTemplateTypes } from './Layout.types';

export default function LayoutTemplate({ pageContext, location }: StoryblokTemplate<LayoutTemplateTypes>) {
  const { blok } = pageContext;

  const header = blok?.content.header?.[0];
  const footer = blok?.content.footer?.[0];
  const notificationBar = {
    linkLabel: blok.content.notification_bar_label,
    link: blok.content.notification_bar_link,
    content: blok.content.notification_bar,
  };

  return (
    <SbEditable content={blok.content}>
      <NotificationBar {...notificationBar} />
      {header && <Header {...header} />}
      {footer && <Footer {...footer} location={location} />}
    </SbEditable>
  );
}
