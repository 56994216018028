// extracted by mini-css-extract-plugin
export var headingH2 = "RichText-module--headingH2--abe86";
export var opacityEnter = "RichText-module--opacityEnter--c0baf";
export var opacityExit = "RichText-module--opacityExit--c06e8";
export var paragraph = "RichText-module--paragraph--42e13";
export var rollDown = "RichText-module--rollDown--97630";
export var rollUp = "RichText-module--rollUp--14452";
export var slideInDown = "RichText-module--slideInDown--170ce";
export var slideOutUp = "RichText-module--slideOutUp--11179";
export var splashEnter = "RichText-module--splashEnter--7b2b4";
export var splashExit = "RichText-module--splashExit--afb31";