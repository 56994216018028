// extracted by mini-css-extract-plugin
export var anchorLink = "ResourceList-module--anchorLink--ee541";
export var letterGroupContainer = "ResourceList-module--letterGroupContainer--8712e";
export var letterHeading = "ResourceList-module--letterHeading--dd182";
export var linksHolder = "ResourceList-module--linksHolder--387a5";
export var opacityEnter = "ResourceList-module--opacityEnter--56576";
export var opacityExit = "ResourceList-module--opacityExit--5fca2";
export var resourceLink = "ResourceList-module--resourceLink--3423b";
export var resourcesListContainer = "ResourceList-module--resourcesListContainer--0166c";
export var rollDown = "ResourceList-module--rollDown--a2206";
export var rollUp = "ResourceList-module--rollUp--be482";
export var slideInDown = "ResourceList-module--slideInDown--57f81";
export var slideOutUp = "ResourceList-module--slideOutUp--a8da3";
export var splashEnter = "ResourceList-module--splashEnter--26c84";
export var splashExit = "ResourceList-module--splashExit--130d4";
export var wrapper = "ResourceList-module--wrapper--3fa22";