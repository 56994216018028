// extracted by mini-css-extract-plugin
export var headingH1 = "Platform-module--headingH1--c2a01";
export var opacityEnter = "Platform-module--opacityEnter--70bb0";
export var opacityExit = "Platform-module--opacityExit--360e0";
export var paragraph = "Platform-module--paragraph--31f46";
export var rollDown = "Platform-module--rollDown--eee3c";
export var rollUp = "Platform-module--rollUp--878a1";
export var root = "Platform-module--root--62bb1";
export var slideInDown = "Platform-module--slideInDown--a808b";
export var slideOutUp = "Platform-module--slideOutUp--5b1ce";
export var splashEnter = "Platform-module--splashEnter--dc441";
export var splashExit = "Platform-module--splashExit--be0b1";