// @ts-nocheck
// eslint-disable-file no-use-before-define

import React from 'react';
import { graphql } from 'gatsby';
import SbEditable from 'storyblok-react';
import BlogPostTemplate from '@/components/templates/BlogPost/BlogPost';
import CustomersTemplate from '@/components/templates/Customers/Customers';
import NewsTemplate from '@/components/templates/News/News';
import MainTemplate from '@/components/templates/Main/Main';
import AboutPageTemplate from '@/components/templates/AboutPage';
import ContactPageTemplate from '@/components/templates/ContactPage';
import CareersPageTemplate from '@/components/templates/CareersPage';
import ContentPageTemplate from '@/components/templates/ContentPage';
import GlossaryPageTemplate from '@/components/templates/GlossaryPage';
import GlobalResourceTemplate from '@/components/templates/GlobalResource';
import GlossaryDetailPageTemplate from '@/components/templates/GlossaryDetailPage';
import PlatformTemplate from '@/components/templates/Platform/Platform';
import HomeTemplate from '@/components/templates/Home/Home';
import DemoPageTemplate from '@/components/templates/DemoPage/DemoPage';
import NotFoundTemplate from '@/components/templates/NotFound/NotFound.editor';
import LayoutTemplate from '@/components/templates/Layout/Layout';
import * as styles from './editor.module.scss';

const RELATIONS = [
  'component_slide.author',
  'section_featured_customer.customer_reference',
  'section_featured.list',
  'section_featured.item',
  'section_related_content.posts',
  'template_blog_post.author',
  'template_speaker.author',
  'template_customers.sidebar_fields',
  'template_blog_post.related_posts',
  'template_customers.quote_author',
  'template_customers.page_config',
  'template_blog_post.page_config',
  'template_blog_post.related_posts',
  'template_news.page_config',
  'template_layout.page_config',
  'template_glossary_page.links',
  'template_glossary_detail_page.related_links',
  'component_leadership_collection.employees',
  'template_global_resource.sponsors',
  'template_global_resource.speakers',
  'template_global_resource.related_posts',
  'template_platform.author',
  'template_home.blog_reference',
];

const TEMPLATES = {
  template_page: MainTemplate,
  template_blog_post: BlogPostTemplate,
  template_customers: CustomersTemplate,
  template_news: NewsTemplate,
  template_about_page: AboutPageTemplate,
  template_contact_page: ContactPageTemplate,
  template_careers_page: CareersPageTemplate,
  template_content_page: ContentPageTemplate,
  template_glossary_page: GlossaryPageTemplate,
  template_glossary_detail_page: GlossaryDetailPageTemplate,
  template_global_resource: GlobalResourceTemplate,
  template_platform: PlatformTemplate,
  template_home: HomeTemplate,
  template_demo_page: DemoPageTemplate,
  template_layout: LayoutTemplate,
  template_404: NotFoundTemplate,
};

const loadStoryblokBridge = cb => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${process.env.GATSBY_STORYBLOK_TOKEN}`;
  script.onload = cb;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const resolveLanguage = path => {
  const language = path.split('/');

  if (language[0] === 'dev-only') return 'dev-only';
  if (language[0].length > 2) return 'en';
  return language[0];
};

const resolvePartial = (layouts, language) => {
  const resolvedLayout = layouts.filter(layout => {
    const splittedSlug = layout.node.full_slug.split('/');
    if (splittedSlug.indexOf(language) !== -1) return layout;
  });

  return resolvedLayout[0].node;
};

const getParam = val => {
  let result = '';
  let tmp = [];

  window.location.search
    .substr(1)
    .split('&')
    .forEach(item => {
      tmp = item.split('=');
      if (tmp[0] === val) result = decodeURIComponent(tmp[1]);
    });

  return result;
};

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { blok: null, config: null, language: null, layout: null };
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();
    });
  }

  loadStory(payload) {
    window.storyblok.get(
      {
        slug: payload.storyId,
        version: 'draft',
        resolve_relations: RELATIONS,
      },
      data => {
        const layout = resolvePartial(this.props.data.layout.edges, this.state.language);
        const config = resolvePartial(this.props.data.config.edges, this.state.language);

        this.setState({ blok: data.story, config, layout });
      },
    );
  }

  initStoryblokEvents() {
    const story = getParam('path');
    const language = resolveLanguage(story);

    this.loadStory({ storyId: story });
    this.setState({ language });

    const sb = window.storyblok;

    sb.init({
      accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
      customParent: 'https://app.storyblok.com',
    });

    sb.on(['change', 'published'], payload => {
      location.reload(true);
      this.loadStory(payload, 'blok');
    });

    sb.on('input', payload => {
      if (this.state.blok && payload.story.id === this.state.blok.id)
        sb.resolveRelations(payload.story, RELATIONS, data => {
          const updatedEntry = { ...payload.story, content: sb.addComments(data, payload.story.id) };
          this.setState({ blok: updatedEntry });
        });
    });

    sb.pingEditor(() => {
      if (sb.inEditor) sb.enterEditmode();
    });
  }

  render() {
    const { blok, config, language, layout } = this.state;

    if (blok == null)
      return (
        <div className={styles.loaderWrapper}>
          <p>State is empty. Loading...</p>
          <div className={styles.loader} />
        </div>
      );

    const PageTemplate = TEMPLATES[blok.content.component];

    if (!PageTemplate)
      return (
        <div className={styles.loaderWrapper}>
          <p>Template not found</p>;
        </div>
      );

    return (
      <SbEditable content={blok.content}>
        <PageTemplate
          pageContext={{
            blok,
            config,
            language,
            layout,
          }}
          location={location}
        />
      </SbEditable>
    );
  }
}

export default StoryblokEntry;

export const query = graphql`
  query LayoutQuery {
    layout: allStoryblokEntry(filter: { field_component: { regex: "/template_layout/" } }) {
      edges {
        node {
          id
          content
          full_slug
          slug
        }
      }
    }
    config: allStoryblokEntry(filter: { field_component: { regex: "/template_page_config/" } }) {
      edges {
        node {
          id
          content
          full_slug
        }
      }
    }
  }
`;
