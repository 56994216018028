// extracted by mini-css-extract-plugin
export var arrow = "ContentPage-module--arrow--d8f58";
export var bold = "ContentPage-module--bold--04c5a";
export var container = "ContentPage-module--container--4e5c3";
export var content = "ContentPage-module--content--31d81";
export var headingH1 = "ContentPage-module--headingH1--0555a";
export var headingH2 = "ContentPage-module--headingH2--9f4d3";
export var headingH3 = "ContentPage-module--headingH3--6c37d";
export var headingH4 = "ContentPage-module--headingH4--2d86b";
export var headingH5 = "ContentPage-module--headingH5--59822";
export var headingH6 = "ContentPage-module--headingH6--206bb";
export var lastUpdated = "ContentPage-module--lastUpdated--96645";
export var link = "ContentPage-module--link--45853";
export var linkWrapper = "ContentPage-module--linkWrapper--c8d83";
export var listOl = "ContentPage-module--listOl--e99e3";
export var opacityEnter = "ContentPage-module--opacityEnter--90354";
export var opacityExit = "ContentPage-module--opacityExit--a7329";
export var paragraph = "ContentPage-module--paragraph--6f1b1";
export var rollDown = "ContentPage-module--rollDown--f9e23";
export var rollUp = "ContentPage-module--rollUp--57c8b";
export var slideInDown = "ContentPage-module--slideInDown--62bd0";
export var slideOutUp = "ContentPage-module--slideOutUp--bffad";
export var splashEnter = "ContentPage-module--splashEnter--3947c";
export var splashExit = "ContentPage-module--splashExit--d7a1c";
export var title = "ContentPage-module--title--c5e46";