import React, { useState, useEffect, useContext } from 'react';
import withLayout from '@/base/containers/withLayout';
import Glossary from '@/components/sections/Glossary';
import { getStories } from '@/base/services/storyblok';
import { LanguageContext } from '@/base/context/Language/provider';
import { GlossaryPageTemplateProps, GlossaryPageTypes, GlossaryPost } from './GlossaryPage.types';

export function GlossaryPage({ content }: StoryblokPage<GlossaryPageTypes>) {
  const {
    state: { language },
  } = useContext(LanguageContext);

  const [posts, setPosts] = useState<StoryblokEntry<GlossaryPost>[]>([]);

  const { title, description } = content;

  function fetchGlossaryPosts() {
    return getStories<GlossaryPost>({
      queryParameters: {
        is_startpage: 0,
        by_slugs: `${language}/glossary/*,${language}/glossario/*`,
      },
    });
  }
  useEffect(() => {
    fetchGlossaryPosts().then(response => {
      if (!response || !response.data) return null;
      return setPosts(response.data);
    });
  }, [language]);

  if (!posts) return null;
  return (
    <div className="container">
      <Glossary title={title} description={description} links={posts} />
    </div>
  );
}

export default function GlossaryPageTemplate({ pageContext, location }: GlossaryPageTemplateProps) {
  return withLayout(GlossaryPage, { ...pageContext, location });
}
