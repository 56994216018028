// extracted by mini-css-extract-plugin
export var activeLetter = "Glossary-module--activeLetter--e2453";
export var description = "Glossary-module--description--471d3";
export var letterWrapper = "Glossary-module--letterWrapper--c223d";
export var lettersBox = "Glossary-module--lettersBox--02f4b";
export var opacityEnter = "Glossary-module--opacityEnter--2422f";
export var opacityExit = "Glossary-module--opacityExit--2664b";
export var rollDown = "Glossary-module--rollDown--f4a22";
export var rollUp = "Glossary-module--rollUp--13c34";
export var singleLetter = "Glossary-module--singleLetter--5ee26";
export var slideInDown = "Glossary-module--slideInDown--43983";
export var slideOutUp = "Glossary-module--slideOutUp--86d96";
export var splashEnter = "Glossary-module--splashEnter--3ad96";
export var splashExit = "Glossary-module--splashExit--146c9";
export var title = "Glossary-module--title--d11ba";
export var wrapper = "Glossary-module--wrapper--c4076";