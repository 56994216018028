import React from 'react';
import { AnchorLinkTypes } from './AnchorLink.types';

export default function AnchorLink({ id, className = '', children }: React.PropsWithChildren<AnchorLinkTypes>) {
  const onClick = () => {
    setTimeout(() => {
      const anchoredSection = document.getElementById(id);
      anchoredSection?.scrollIntoView();
    }, 500);
  };

  return (
    <button onClick={onClick} className={className ? className : ''}>
      {children}
    </button>
  );
}
