import React from 'react';
import withLayout from '@/base/containers/withLayout';
import SectionRichText from '@/components/sections/SectionRichText/SectionRichText';
import { ContentPageTemplateProps, ContentPageTypes } from './ContentPage.types';
import * as styles from './ContentPage.module.scss';

export function ContentPage({ name, content }: StoryblokPage<ContentPageTypes>) {
  const { body } = content;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{name}</h1>
        {body && <SectionRichText body={body} className={styles} />}
      </div>
    </div>
  );
}

export default function ContentPageTemplate({ pageContext, location }: ContentPageTemplateProps) {
  return withLayout(ContentPage, { ...pageContext, location });
}
