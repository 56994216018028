// extracted by mini-css-extract-plugin
export var arrow = "News-module--arrow--5462d";
export var bodyWrapper = "News-module--bodyWrapper--e8297";
export var image = "News-module--image--fb7e1";
export var link = "News-module--link--a5567";
export var linkBackWrapper = "News-module--linkBackWrapper--afcf7";
export var opacityEnter = "News-module--opacityEnter--0049a";
export var opacityExit = "News-module--opacityExit--6075d";
export var rollDown = "News-module--rollDown--2b9e7";
export var rollUp = "News-module--rollUp--7e943";
export var slideInDown = "News-module--slideInDown--5f4f7";
export var slideOutUp = "News-module--slideOutUp--be5f2";
export var splashEnter = "News-module--splashEnter--2e559";
export var splashExit = "News-module--splashExit--0d1b9";