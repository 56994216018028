import React from 'react';
import Hero from '@/components/sections/Hero';
import withLayout from '@/base/containers/withLayout';
import { isArrayNotEmpty } from '@/base/helpers/arrays';
import { Button } from '@/components/elements/Button';
import loadable from '@loadable/component';

const Timeline = loadable(() => import('../../sections/Timeline'));
const ThreeColumn = loadable(() => import('../../sections/ThreeColumn'));
const TwoColumn = loadable(() => import('../../sections/TwoColumn'));
const SectionRichText = loadable(() => import('../../sections/SectionRichText'));
const TabsSlider = loadable(() => import('../../sections/TabsSlider'));
const CTASection = loadable(() => import('../../sections/CTASection'));

import type { HomeTypes, HomeTemplateProps } from './Home.types';
import * as styles from './Home.module.scss';

const twoColumnsStyles = {
  root: styles.twoColumnRoot,
  headingH2: styles.twoColumnHeading,
  headingH3: styles.twoColumnHeading,
  paragraph: styles.twoColumnParagraph,
  link: styles.twoColumnLink,
  image: styles.twoColumnImage,
};

const textSectionStyles = {
  root: styles.textRoot,
  headingH2: styles.textHeading,
  headingH3: styles.textHeading,
  paragraph: styles.textParagraph,
};

export function Home({ content }: StoryblokPage<HomeTypes>) {
  const {
    image,
    body,
    title,
    steps,
    blog_reference,
    button_label,
    text_body,
    two_columns,
    timeline_body,
    timeline_steps,
    timeline_eyebrow,
    slider_title,
    slides,
    bottom_link,
    bottom_link_label,
    video,
    cta,
  } = content;

  return (
    <section className="container">
      <Hero
        body={body}
        image={image}
        image_position="right"
        video={video}
        className={styles}
        top_spacing="sm"
        bottom_spacing="sm"
        widestContent
      />
      <ThreeColumn
        grid={steps}
        title={title}
        post={blog_reference}
        postButton={button_label}
        top_spacing="sm"
        bottom_spacing="sm"
        solidVariant
        biggerHeadings
      />
      {text_body && (
        <SectionRichText
          body={text_body}
          className={textSectionStyles}
          topSpacing="md"
          bottomSpacing="sm"
          mobileTopSpacing="sm"
          mobileBottomSpacing="sm"
          arrowLinks
        />
      )}
      {two_columns && (
        <ul>
          {two_columns.map(blok => (
            <li key={blok._uid} className={styles.twoColumnsItem}>
              <TwoColumn {...blok} top_spacing="md" bottom_spacing="md" className={twoColumnsStyles} arrowLinks />
            </li>
          ))}
        </ul>
      )}
      <Timeline eyebrow={timeline_eyebrow} body={timeline_body} steps={timeline_steps} />
      {isArrayNotEmpty(slides) && (
        <div className={styles.sliderWrapper}>
          {slider_title && <h2 className={styles.sliderTitle}>{slider_title}</h2>}
          <TabsSlider slides={slides} />
          {bottom_link && (
            <div className={styles.buttonWrapper}>
              <Button variant="secondary" link={bottom_link}>
                {bottom_link_label}
              </Button>
            </div>
          )}
        </div>
      )}
      {cta && <CTASection mobile_top_spacing="sm" background="blue" variant="wide" body={cta} />}
    </section>
  );
}

export default function HomeTemplate({ pageContext, location }: HomeTemplateProps) {
  return withLayout(Home, { ...pageContext, location });
}
