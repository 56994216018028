import React, { useContext, useEffect } from 'react';
import { ConfigContext } from '@/base/context/Config/provider';
import { setScrollBehaviour } from '@/base/helpers/styles';
import withLayout from '@/base/containers/withLayout';
import SectionRichText from '@/components/sections/SectionRichText/SectionRichText';
import loadable from '@loadable/component';

import { CareersPageTemplateProps, CareersPageTypes } from './CareersPage.types';
import * as styles from './CareersPage.module.scss';
import * as richTextStyles from './RichText.module.scss';

const CareersPageImageSection = loadable(() => import('../../sections/CareersPageImageSection'));
const CoreValuesSection = loadable(() => import('../../sections/CoreValuesSection'));
const SliderSection = loadable(() => import('../../sections/SliderSection'));
const BenefitsSection = loadable(() => import('../../sections/BenefitsSection'));
const JobOffersSection = loadable(() => import('../../sections/JobOffersSection'));

export function CareersPage({
  content,
}: StoryblokEntry<CareersPageTypes> & {
  location: Location;
}) {
  const {
    state: { config },
  } = useContext(ConfigContext);

  const {
    hero_body,
    image_1,
    image_2,
    image_3,
    image_4,
    card,
    vision_eyebrow,
    vision_title,
    mission_eyebrow,
    mission_title,
    values_eyebrow,
    core_values,
    slider_body,
    slides,
    stats,
    benefits_image,
    benefits_title,
    benefits_body,
    jobs_title,
    search_placeholder,
  } = content;

  useEffect(() => {
    setScrollBehaviour('smooth');
    return () => setScrollBehaviour('unset');
  }, []);

  return (
    <section>
      <div className={styles.container}>
        <div className={styles.heroWrapper}>
          <SectionRichText body={hero_body} className={styles} />
        </div>
        <CareersPageImageSection
          body={card}
          topImage={image_1}
          middleLeftImage={image_2}
          middleRightImage={image_3}
          bottomImage={image_4}
        />
      </div>
      <CoreValuesSection
        coreValues={core_values}
        coreValuesUpperTitle={values_eyebrow}
        missionTitle={mission_title}
        missionUpperTitle={mission_eyebrow}
        visionTitle={vision_title}
        vissionUpperTitle={vision_eyebrow}
      />
      <div className={styles.container}>
        <div className={styles.richTextWrapper}>
          <SectionRichText body={slider_body} className={richTextStyles} />
        </div>
      </div>
      <div className={styles.sliderWrapper}>
        <SliderSection slides={slides} />
      </div>
      <BenefitsSection benefits={benefits_body} image={benefits_image} title={benefits_title} statistics={stats} />
      <JobOffersSection
        placeholder={search_placeholder}
        title={jobs_title}
        noOpeningsMessage={config?.careers_no_openings_message}
      />
    </section>
  );
}

export default function CareersPageTemplate({ pageContext, location }: CareersPageTemplateProps) {
  return withLayout(CareersPage, { ...pageContext, location });
}
