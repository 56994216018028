import React from 'react';
import AnchorLink from '@/components/elements/AnchorLink';
import { ResourceList } from '@/components/modules/ResourceList';
import { groupLinksByLetter } from '@/base/utils/groupLinksByLetter';
import { GlossaryTypes } from './Glossary.types';
import * as styles from './Glossary.module.scss';

export default function Glossary({ title, description, links }: GlossaryTypes) {
  const sortedLinks = links.sort((a, b) => {
    if (a.content?.list_page_title)
      return a.content?.list_page_title.localeCompare(b.content?.list_page_title || b.name);

    return a.name.localeCompare(b.content?.list_page_title || b.name);
  });
  const groupedLinksObject = groupLinksByLetter(sortedLinks);
  const groupedLinks = groupedLinksObject && Object.values(groupedLinksObject);
  const groupedLetters = groupedLinksObject && Object.keys(groupedLinksObject);
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  return (
    <div className={styles.wrapper}>
      <h1 className={`mb-5 ${styles.title}`}>{title}</h1>
      <p className={`mb-10 ${styles.description}`}>{description}</p>
      <div className="flex flex-col items-center md-flex-row-reverse md-align-start md-justify-between">
        <ul className={`py-10 px-5 col-md-4 mt-0 row m-0-auto mb-10 mb-md-0 ${styles.lettersBox}`}>
          {alphabet.map(letter => (
            <li key={letter} className={`text-center ${styles.letterWrapper} `}>
              <AnchorLink
                id={`section-${letter}`}
                className={`p-0 ${styles.singleLetter} ${groupedLetters.includes(letter) && styles.activeLetter}`}
              >
                {letter}
              </AnchorLink>
            </li>
          ))}
        </ul>
        <div className="row mt-0 col-md-7">
          <ResourceList resourcesList={groupedLinks} />
        </div>
      </div>
    </div>
  );
}
