import React, { useContext } from 'react';
import Image from '@/components/elements/Image';
import { ConfigContext } from '@/base/context/Config/provider';
import { shortcutLiteralMonthDateFormat } from '@/base/helpers/dates';
import withLayout from '@/base/containers/withLayout';
import Link from '@/components/elements/Link';
import RelatedPosts from '@/components/modules/RelatedPosts';
import HeroCenterContentVariant from '@/components/sections/HeroCenterContentVariant';
import SectionRichText from '@/components/sections/SectionRichText';
import { NewsTypes, NewsTemplatesProps } from './News.types';
import * as styles from './News.module.scss';

export function News({ content, first_published_at, full_slug, name }: StoryblokPage<NewsTypes>) {
  const {
    state: { config },
  } = useContext(ConfigContext);

  const label = config?.news_card_link_label || config?.card_link_label;

  return (
    <section>
      {config?.news_back_link?.cached_url && (
        <div className={styles.linkBackWrapper}>
          <div className={`${styles.linkBackWrapper} container linkBack`}>
            <Link type="internal" href={`/${config?.news_back_link?.cached_url}`} arrow_direction="left" display_arrow>
              {config.news_back_link_label}
            </Link>
          </div>
        </div>
      )}
      <HeroCenterContentVariant
        {...content}
        date={shortcutLiteralMonthDateFormat(first_published_at)}
        category={content.news_category}
        title={name}
      >
        {content.image && <Image data={content.image} fluid className={styles.image} />}
      </HeroCenterContentVariant>
      {content.body && (
        <div className={styles.bodyWrapper}>
          <SectionRichText body={content.body} className={styles} />
        </div>
      )}
      <RelatedPosts slug={full_slug} title={config?.news_related_title} label={label} />
    </section>
  );
}

export default function NewsTemplate({ pageContext, location }: NewsTemplatesProps) {
  return withLayout(News, { ...pageContext, location });
}
